[data-theme="dark"]
  .head-selection
    margin-bottom: 30px
    display: flex
    align-items: center
    gap: 24px
    width: 100%
    justify-content: space-between

    .head-selection-line
      display: flex
      gap: 10px
      overflow-x: auto
      position: relative

      &::-webkit-scrollbar
        display: none

    .head-selection-line
      --scrollbar-foreground: #262626
      --scrollbar-background: #1e1e1e
      --scrollbar-size: 16px
      scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background)

      &::-webkit-scrollbar
        width: var(--scrollbar-size)
        height: var(--scrollbar-size)

      &::-webkit-scrollbar-thumb
        border-radius: 7px
        border: 6px solid var(--scrollbar-background)
        border-left: none
        border-right: none
        background: var(--scrollbar-foreground)

      &::-webkit-scrollbar-track
        border-radius: 7px
        background: var(--scrollbar-background)

    .head-selection-item
      border-radius: $border-radius
      background: transparent
      transition: $transition

      .head-selection-item-btn
        display: flex
        align-items: center
        padding: 12px 16px
        text-decoration: none
        opacity: 0.5
        transition: $transition
        background: transparent

        i
          margin-right: 6px
          margin-left: -10px

      &:hover
        background: $clr-26

      &:active, &.active
        background: $clr-26

        .head-selection-item-btn
          opacity: 1

      &:disabled, &.disabled
        background: $clr-26
        pointer-events: none
        opacity: 0.5

        .head-selection-item-btn
          opacity: 0.5

      a
        display: flex
        align-items: center
        padding: 12px 20px
        text-decoration: none
        opacity: 0.5
        transition: $transition

        i
          margin-right: 6px
          margin-left: -10px

      &:hover
        background: $clr-26

      &:active, &.active
        background: $clr-26

        a
          opacity: 1

      &:disabled, &.disabled
        background: $clr-26
        pointer-events: none
        opacity: 0.5

        a
          opacity: 0.25

      &.back
        background: $clr-26

        &:hover
          background: $clr-26

          a
            opacity: 1

    .more
      border-radius: $border-radius
      background: $clr-26
      transition: $transition

      a
        display: flex
        align-items: center
        padding: 12px 12px
        text-decoration: none
        opacity: 0.5
        transition: $transition

      &:hover
        background: $clr-2e

      &:active, &.active
        background: $clr-4e

        a
          opacity: 1

      &:disabled, &.disabled
        background: $clr-26
        pointer-events: none
        opacity: 0.5

        a
          opacity: 0.25

    .search-block
      position: relative

      input
        width: 300px
        box-sizing: border-box
        padding: 12px 24px 12px 50px

      .search-btn
        align-items: center
        display: flex
        justify-content: center
        position: absolute
        left: 0
        top: 0
        background: transparent
        margin: 0
        padding: 12px 10px 12px 16px
        align-items: center
        display: flex
        justify-content: center
        opacity: .5

      .remove-btn
        align-items: center
        display: flex
        justify-content: center
        position: absolute
        right: 16px
        top: 12px
        background: transparent
        margin: 0
        padding: 0
        transition: $transition
        cursor: pointer
        align-items: center
        display: flex
        justify-content: center
        opacity: .5
        z-index: 1

        &:hover
          opacity: 1

        &:active
          opacity: .8

[data-theme="light"]
  .head-selection
    margin-bottom: 30px
    display: flex
    align-items: center
    gap: 24px
    width: 100%
    justify-content: space-between

    .head-selection-line
      display: flex
      gap: 10px
      overflow-x: auto
      position: relative

      &::-webkit-scrollbar
        display: none

    .head-selection-line
      --scrollbar-foreground: #F5F5F5
      --scrollbar-background: #fff
      --scrollbar-size: 16px
      scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background)

      &::-webkit-scrollbar
        width: var(--scrollbar-size)
        height: var(--scrollbar-size)

      &::-webkit-scrollbar-thumb
        border-radius: 7px
        border: 6px solid var(--scrollbar-background)
        border-left: none
        border-right: none
        background: var(--scrollbar-foreground)

      &::-webkit-scrollbar-track
        border-radius: 7px
        background: var(--scrollbar-background)

    .head-selection-item
      border-radius: $border-radius
      background: transparent
      transition: $transition

      .head-selection-item-btn
        display: flex
        align-items: center
        padding: 12px 16px
        text-decoration: none
        opacity: 1
        transition: $transition
        background: transparent

        i
          margin-right: 6px
          margin-left: -10px

      &:hover
        background: $clr-f5

      &:active, &.active
        background: $clr-26

        .head-selection-item-btn
          opacity: 1

      &:disabled, &.disabled
        background: $clr-26
        pointer-events: none
        opacity: 0.5

        .head-selection-item-btn
          opacity: 1

      a
        display: flex
        align-items: center
        padding: 12px 20px
        text-decoration: none
        opacity: 1
        transition: $transition

        i
          margin-right: 6px
          margin-left: -10px

      &:hover
        background: $clr-f5

        a
          opacity: 1

      &:active, &.active
        background: $clr-f5

        a
          opacity: 1

      &:disabled, &.disabled
        background: $clr-f5
        pointer-events: none
        opacity: 0.5

        a
          opacity: 0.5

          span
            color: $clr-black

      &.back
        background: $clr-f5

        &:hover
          background: $clr-f5

          a
            opacity: 1

    .more
      border-radius: $border-radius
      background: $clr-26
      transition: $transition

      a
        display: flex
        align-items: center
        padding: 12px 12px
        text-decoration: none
        opacity: 0.5
        transition: $transition

      &:hover
        background: $clr-2e

      &:active, &.active
        background: $clr-4e

        a
          opacity: 1

      &:disabled, &.disabled
        background: $clr-26
        pointer-events: none
        opacity: 0.5

        a
          opacity: 0.25

    .search-block
      position: relative

      input
        width: 300px
        box-sizing: border-box
        padding: 11px 24px 11px 50px

      .search-btn
        align-items: center
        display: flex
        justify-content: center
        position: absolute
        left: 0
        top: 0
        background: transparent
        margin: 0
        padding: 12px 10px 12px 16px
        align-items: center
        display: flex
        justify-content: center
        opacity: .5

      .remove-btn
        align-items: center
        display: flex
        justify-content: center
        position: absolute
        right: 16px
        top: 12px
        background: transparent
        margin: 0
        padding: 0
        transition: $transition
        cursor: pointer
        align-items: center
        display: flex
        justify-content: center
        opacity: .5
        z-index: 1

        &:hover
          opacity: 1

        &:active
          opacity: .8