$background: #1E1E1E
$background-wht: #fff

$clr-white: #fff
$clr-black: #1E1E1E
$clr-f0: #F0F0F0
$clr-f5: #F5F5F5
$clr-df: #DFE4E7
$clr-e2: #E2E2E2
$clr-8e: #8E8E8E
$clr-4e: #4E4E4E
$clr-2e: #2E2E2E
$clr-22: #222222
$clr-26: #262626
$clr-red: #FF3131
$clr-yel: #F3DF74
$clr-yel2: #FFF0A1
$clr-yel3: #F3DF74
$clr-grn: #3FA601

$container-width: 100%
$bs-gutter-x: 1.5rem
$bs-gutter-y: 0

$border-radius: 10px
$border-radius-2x: 6px

$transition: all .2s 0s ease



