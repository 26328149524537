[data-theme="dark"]
  header
    padding: 20px 0
    border-bottom: 1px solid $clr-2e
    background: $clr-black

    .container
      display: flex
      align-items: center
      justify-content: space-between

    .left-block
      .header-logo
        display: flex
        transition: $transition

        a
          color: $clr-white
          text-decoration: none
          cursor: pointer
          transition: $transition
          opacity: 1

        button
          text-decoration: none

          &:hover
            text-decoration: none

        &.button-hover
          &:hover
            text-decoration: none

        &:hover
          color: $clr-white
          text-decoration: none
          opacity: .8

        &:active
          color: $clr-white
          text-decoration: none
          opacity: .5

    .right-block
      display: flex
      align-items: center
      gap: 24px

      .theme-switch
        a
          transition: $transition
          opacity: 1

          &:hover
            opacity: .8

          &:active
            opacity: .5

[data-theme="light"]
  header
    padding: 20px 0
    border-bottom: 1px solid $clr-e2
    background: $clr-white

    .container
      display: flex
      align-items: center
      justify-content: space-between

    .left-block
      .header-logo
        display: flex
        transition: $transition

        a
          color: $clr-white
          text-decoration: none
          cursor: pointer
          transition: $transition
          opacity: 1

        button
          text-decoration: none

          &:hover
            text-decoration: none

        &.button-hover
          &:hover
            text-decoration: none

        &:hover
          color: $clr-white
          text-decoration: none
          opacity: .8

        &:active
          color: $clr-white
          text-decoration: none
          opacity: .5

    .right-block
      display: flex
      align-items: center
      gap: 24px

      .theme-switch
        a
          transition: $transition
          opacity: 1

          &:hover
            opacity: .8

          &:active
            opacity: .5