[data-theme="dark"]
  .setting-page
    .setting-edit
      form
        h3
          margin-bottom: 24px
        .grid-input
          grid-gap: 12px 24px
          display: grid
          grid-template-columns: repeat(2, 1fr)
          grid-template-rows: auto
          margin-bottom: 24px

          &.grid-three
            grid-template-columns: repeat(3, 1fr)

          label
            position: relative

            p
              margin-bottom: 12px
              color: $clr-8e

            input
              box-sizing: border-box
              width: 100%

            .password-toggle
              cursor: pointer
              background: none
              border: none
              position: absolute
              right: 0
              top: 0
              margin: 0
              padding: 12px
              opacity: 0.5
              transition: $transition

              &:hover
                opacity: 1

              &:active
                opacity: 0.8


        .button-block
          display: flex
          margin-top: 40px
          align-items: center
          gap: 40px
          justify-content: space-between

          .delete-user-btn
            display: flex
            align-items: center
            gap: 12px
            color: $clr-white
            opacity: 0.5
            transition: $transition
            cursor: pointer
            border-radius: $border-radius
            background: $clr-26
            padding: 12px 20px

            i
              &:before
                width: 20px
                height: 20px
                background-size: 20px

            &:hover
              opacity: 1

            &:active
              opacity: 0.8

[data-theme="light"]
  .setting-page
    .setting-edit
      form
        h3
          margin-bottom: 24px
        .grid-input
          grid-gap: 12px 24px
          display: grid
          grid-template-columns: repeat(2, 1fr)
          grid-template-rows: auto
          margin-bottom: 24px

          &.grid-three
            grid-template-columns: repeat(3, 1fr)

          label
            position: relative

            p
              margin-bottom: 12px
              color: $clr-8e

            input
              box-sizing: border-box
              width: 100%

            .password-toggle
              cursor: pointer
              background: none
              border: none
              position: absolute
              right: 0
              top: 0
              margin: 0
              padding: 12px
              opacity: 0.5
              transition: $transition

              &:hover
                opacity: 1

              &:active
                opacity: 0.8


        .button-block
          display: flex
          margin-top: 40px
          align-items: center
          gap: 40px
          justify-content: space-between

          .delete-user-btn
            display: flex
            align-items: center
            gap: 12px
            color: $clr-white
            opacity: 0.5
            transition: $transition
            cursor: pointer
            border-radius: $border-radius
            background: $clr-26
            padding: 12px 20px

            i
              &:before
                width: 20px
                height: 20px
                background-size: 20px

            &:hover
              opacity: 1

            &:active
              opacity: 0.8