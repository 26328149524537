[data-theme="dark"]
  .floating-block
    background: $clr-black
    border-radius: $border-radius-2x
    transition: $transition
    display: flex
    flex-direction: column
    z-index: 99
    position: fixed
    bottom: 30px
    right: 30px
    padding: 0
    gap: 4px
    opacity: 0.8

    &:hover
      opacity: 1

[data-theme="light"]
  .floating-block
    background: $clr-white
    border-radius: $border-radius-2x
    transition: $transition
    display: flex
    flex-direction: column
    z-index: 99
    position: fixed
    bottom: 30px
    right: 30px
    padding: 0
    gap: 4px
    opacity: 0.8

    &:hover
      opacity: 1