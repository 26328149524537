[data-theme="dark"]
  .seller
    backdrop-filter: blur(12px)
    background: linear-gradient(252.47deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.024) 100%)
    display: flex
    flex-direction: column
    height: fit-content
    overflow: hidden
    position: relative
    padding: 26px
    border-radius: $border-radius
    top: auto

    &:before
      background: linear-gradient(252.47deg, hsla(0, 0%, 100%, 1), hsla(0, 0%, 100%, 0.4))
      border-radius: $border-radius
      content: ""
      inset: 0
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)
      -webkit-mask-composite: xor
      mask-composite: exclude
      padding: 1px
      position: absolute
      width: calc(100% - 2px)
      z-index: -1
      opacity: .06

    .seller-info
      display: flex
      gap: 16px
      align-items: center
      margin-bottom: 26px

      .seller-avatar
        border-radius: 50px
        overflow: hidden
        display: flex
        align-items: center
        justify-content: center
        width: 50px
        height: 50px
        flex-shrink: 0

        img
          width: 50px
          height: 50px
          object-fit: cover

      .seller-name
        display: flex
        flex-direction: column
        gap: 2px
        overflow: hidden

        p
          line-height: 14px
          color: $clr-8e
          text-transform: uppercase

        h2
          flex-shrink: 0
          width: 100%
          white-space: nowrap
          text-overflow: ellipsis
          overflow: hidden

    .seller-amount
      display: flex
      align-items: center
      justify-content: space-between
      flex-wrap: wrap
      gap: 10px

      .seller-amount-item

        p
          line-height: 14px
          color: $clr-8e
          text-transform: uppercase
          display: flex
          gap: 4px

          i
            opacity: 0.5
            transition: $transition

            &:hover
              opacity: 1

            &:active
              opacity: 0.8

        .seller-amount-total
          display: flex
          gap: 4px
          align-items: flex-end

      .total-item
        cursor: pointer
        transition: $transition

        &:hover
          opacity: 0.5

        &:active
          opacity: 0.8

  .sticky-seller
    position: fixed
    top: 24px
    width: inherit
    box-sizing: border-box

  .artist-list
    margin-top: 24px

    ul
      display: flex
      gap: 10px
      flex-wrap: wrap

      li
        color: $clr-white
        opacity: 0.5
        border-radius: 6px
        border: 1px solid #2e2e2e
        transition: $transition
        position: relative

        button
          padding: 4px 12px
          color: $clr-white
          text-decoration: none
          background: transparent
          border-radius: 0
          
          &:before
            content: ""
            position: absolute
            left: 0
            right: 0
            top: 0
            bottom: 0

        &:hover
          background: #262626
          border: 1px solid #262626
          opacity: 1

        &.active
          background: #262626
          border: 1px solid #262626
          opacity: 1

[data-theme="light"]
  .seller
    backdrop-filter: blur(12px)
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.01) 0%, rgba(0, 0, 0, 0.02) 100%)
    display: flex
    flex-direction: column
    height: fit-content
    overflow: hidden
    position: relative
    padding: 26px
    border-radius: $border-radius

    &:before
      background: linear-gradient(252.47deg, rgb(0 0 0), rgb(0 0 0 / 40%))
      border-radius: $border-radius
      content: ""
      inset: 0
      -webkit-mask: linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0)
      -webkit-mask-composite: xor
      mask-composite: exclude
      padding: 1px
      position: absolute
      width: calc(100% - 2px)
      z-index: -1
      opacity: .06

    .seller-info
      display: flex
      gap: 16px
      align-items: center
      margin-bottom: 26px

      .seller-avatar
        border-radius: 50px
        overflow: hidden
        display: flex
        align-items: center
        justify-content: center
        width: 50px
        height: 50px
        flex-shrink: 0

        img
          width: 50px
          height: 50px
          object-fit: cover

      .seller-name
        display: flex
        flex-direction: column
        gap: 2px
        overflow: hidden

        p
          line-height: 14px
          color: $clr-8e
          text-transform: uppercase

        h2
          flex-shrink: 0
          width: 100%
          white-space: nowrap
          text-overflow: ellipsis
          overflow: hidden

    .seller-amount
      display: flex
      align-items: center
      justify-content: space-between
      flex-wrap: wrap
      gap: 10px

      .seller-amount-item

        p
          line-height: 14px
          color: $clr-8e
          text-transform: uppercase
          display: flex
          gap: 4px

          i
            opacity: 0.5
            transition: $transition

            &:hover
              opacity: 1

            &:active
              opacity: 0.8

        .seller-amount-total
          display: flex
          gap: 4px
          align-items: flex-end

      .total-item
        cursor: pointer
        transition: $transition

        &:hover
          opacity: 0.5

        &:active
          opacity: 0.8

  .sticky-seller
    position: fixed
    top: 24px
    width: inherit
    box-sizing: border-box

  .artist-list
    margin-top: 24px

    ul
      display: flex
      gap: 10px
      flex-wrap: wrap

      li
        color: $clr-black
        opacity: 1
        border-radius: 6px
        border: 1px solid $clr-e2
        transition: $transition
        position: relative

        button
          padding: 4px 12px
          color: $clr-black
          text-decoration: none
          background: transparent
          border-radius: 0

          &:before
            content: ""
            position: absolute
            left: 0
            right: 0
            top: 0
            bottom: 0

        &:hover
          background: $clr-f5
          border: 1px solid $clr-f5
          opacity: 1

        &.active
          background: $clr-f5
          border: 1px solid $clr-f5
          opacity: 1