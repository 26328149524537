[data-theme="dark"]
  .sign
    display: flex
    align-items: center
    justify-content: center

    .auth-block
      width: auto
      height: auto
      background: $clr-black
      display: flex
      align-items: center
      justify-content: center
      position: absolute
      border-radius: 10px
      padding: 50px
      z-index: 2
      max-width: 500px
      box-sizing: border-box
      border: none
      box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2)

      .sign-block
        .sign-logo
          display: flex
          align-items: center
          flex-direction: column
          gap: 16px

          span
            color: $clr-8e

          a
            color: $clr-white
            text-decoration: none
            cursor: pointer
            transition: $transition
            opacity: 1

            &:hover
              color: $clr-white
              text-decoration: none
              opacity: .8

            &:active
              color: $clr-white
              text-decoration: none
              opacity: .5

        .sign-form
          margin-top: 50px

          .sign-form-title
            h1
              text-align: center
              margin-bottom: 30px
              color: $clr-white

          form
            min-width: 416px
            display: flex
            flex-direction: column
            gap: 18px

            .label-blind
              display: flex
              gap: 10px

            label
              position: relative

              input
                width: 100%
                box-sizing: border-box

                &.error
                  background: rgba(255, 49, 49, 0.10)
                  border: 1px solid $clr-red

              p
                margin-top: 8px

                &.error
                  color: $clr-red

                &.verity
                  color: $clr-grn

            button
              margin-top: 16px

            .password-toggle
              cursor: pointer
              background: none
              border: none
              position: absolute
              right: 0
              top: 0
              margin: 0
              padding: 12px
              opacity: 0.5
              transition: $transition

              &:hover
                opacity: 1

              &:active
                opacity: 0.8

          .sign-form-link
            margin-top: 24px
            display: flex
            align-items: center
            //flex-direction: column
            gap: 10px

            a, button
              width: 100%
              box-sizing: border-box


        .sign-soc
          margin-top: 50px
          display: flex
          gap: 30px
          align-items: center
          justify-content: center

  .bg-block
    width: 100%
    height: 100vh
    background: $clr-black
    display: flex
    align-items: center
    justify-content: center
    opacity: 1

    .bg-sign
      width: 100%
      height: 100%
      background: url("../../../public/img/bgfull.jpg")
      background-size: cover
      background-position: center
      opacity: 1
      filter: blur(10px)
      transform: rotate(180deg)
      //opacity: 0.7
      //filter: blur(50px)
      //transform: rotate(180deg)

    &:before
      content: ""
      background: linear-gradient(180deg, rgba(30,30,30,0) 0%, rgba(30,30,30,0.5) 50%, rgba(30,30,30,1) 100%)
      z-index: 1
      width: 100vw
      height: 100vh
      position: absolute
      opacity: 0.5

  .info-form
    padding: 18px
    border: 1px solid $clr-4e
    border-radius: 10px
    text-align: center
    background: $clr-26
    transition: $transition

    &.err
      margin-top: 18px

    &.acc
      margin-top: 18px

    p
      color: $clr-8e

  .logo-title
    text-align: center

  .function-block
    margin-top: 40px
    margin-bottom: 40px
    padding: 30px
    background: $clr-26
    border-radius: $border-radius
    display: flex
    flex-direction: column
    gap: 24px
    min-width: 344px

    .function-item
      gap: 16px
      display: flex

      .icon
        margin-top: 1px

      .txt
        span
          color: #8e8e8e

  .hello-logo
    width: 68px
    height: 68px
    background: url("../../../public/img/hello.png")
    background-size: cover
    background-position: center
    position: relative

  .next-block
    width: 100%
    margin-top: 40px

    button
      width: 100%

  .slider-thumb
    &::before
      position: absolute
      content: ""
      left: 30%
      top: 20%
      width: 450px
      height: 450px
      background: #17141d
      border-radius: 62% 47% 82% 35% / 45% 45% 80% 66%
      will-change: border-radius, transform, opacity
      animation: sliderShape 5s linear infinite
      display: block
      z-index: -1
      -webkit-animation: sliderShape 5s linear infinite

  @keyframes sliderShape
    0%,100%
      border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%
      transform: translate3d(0,0,0) rotateZ(0.01deg)

    34%
      border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%
      transform:  translate3d(0,5px,0) rotateZ(0.01deg)

    50%
      transform: translate3d(0,0,0) rotateZ(0.01deg)

    67%
      border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%
      transform: translate3d(0,-3px,0) rotateZ(0.01deg)

[data-theme="light"]
  .sign
    display: flex
    align-items: center
    justify-content: center

    .auth-block
      width: auto
      height: auto
      background: $clr-white
      display: flex
      align-items: center
      justify-content: center
      position: absolute
      border-radius: 10px
      padding: 50px
      z-index: 2
      max-width: 500px
      box-sizing: border-box
      border: none
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2)

      .sign-block
        .sign-logo
          display: flex
          align-items: center
          flex-direction: column
          gap: 16px

          span
            color: $clr-8e

          a
            color: $clr-black
            text-decoration: none
            cursor: pointer
            transition: $transition
            opacity: 1

            &:hover
              color: $clr-white
              text-decoration: none
              opacity: .8

            &:active
              color: $clr-white
              text-decoration: none
              opacity: .5

        .sign-form
          margin-top: 50px

          .sign-form-title
            h1
              text-align: center
              margin-bottom: 30px
              color: $clr-black

          form
            min-width: 416px
            display: flex
            flex-direction: column
            gap: 18px

            .label-blind
              display: flex
              gap: 10px

            label
              position: relative

              input
                width: 100%
                box-sizing: border-box

                &.error
                  background: rgba(255, 49, 49, 0.10)
                  border: 1px solid $clr-red

              p
                margin-top: 8px

                &.error
                  color: $clr-red

                &.verity
                  color: $clr-grn

            button
              margin-top: 16px

            .password-toggle
              cursor: pointer
              background: none
              border: none
              position: absolute
              right: 0
              top: 0
              margin: 0
              padding: 12px
              opacity: 0.5
              transition: $transition

              &:hover
                opacity: 1

              &:active
                opacity: 0.8

          .sign-form-link
            margin-top: 24px
            display: flex
            align-items: center
            //flex-direction: column
            gap: 10px

            a, button
              width: 100%
              box-sizing: border-box


        .sign-soc
          margin-top: 50px
          display: flex
          gap: 30px
          align-items: center
          justify-content: center

  .bg-block
    width: 100%
    height: 100vh
    background: $clr-black
    display: flex
    align-items: center
    justify-content: center
    opacity: 1

    .bg-sign
      width: 100%
      height: 100%
      background: url("../../../public/img/bgfull.jpg")
      background-size: cover
      background-position: center
      opacity: 1
      filter: blur(10px)
      transform: rotate(180deg)
    //opacity: 0.7
    //filter: blur(50px)
    //transform: rotate(180deg)

    &:before
      content: ""
      background: linear-gradient(180deg, rgba(30,30,30,0) 0%, rgba(30,30,30,0.5) 50%, rgba(30,30,30,1) 100%)
      z-index: 1
      width: 100vw
      height: 100vh
      position: absolute
      opacity: 0.5

  .info-form
    padding: 18px
    border: 1px solid $clr-e2
    border-radius: 10px
    text-align: center
    background: $clr-f5
    transition: $transition

    &.err
      margin-top: 18px

    &.acc
      margin-top: 18px

    p
      color: $clr-8e

  .logo-title
    text-align: center

  .function-block
    margin-top: 40px
    margin-bottom: 40px
    padding: 30px
    background: $clr-f5
    border-radius: $border-radius
    display: flex
    flex-direction: column
    gap: 24px
    min-width: 344px

    .function-item
      gap: 16px
      display: flex

      .icon
        margin-top: 1px

      .txt
        span
          color: #8e8e8e

  .hello-logo
    width: 68px
    height: 68px
    background: url("../../../public/img/hello.png")
    background-size: cover
    background-position: center
    position: relative

  .next-block
    width: 100%
    margin-top: 40px

    button
      width: 100%

  .slider-thumb
    &::before
      position: absolute
      content: ""
      left: 30%
      top: 20%
      width: 450px
      height: 450px
      background: #17141d
      border-radius: 62% 47% 82% 35% / 45% 45% 80% 66%
      will-change: border-radius, transform, opacity
      animation: sliderShape 5s linear infinite
      display: block
      z-index: -1
      -webkit-animation: sliderShape 5s linear infinite

  @keyframes sliderShape
    0%,100%
      border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%
      transform: translate3d(0,0,0) rotateZ(0.01deg)

    34%
      border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%
      transform:  translate3d(0,5px,0) rotateZ(0.01deg)

    50%
      transform: translate3d(0,0,0) rotateZ(0.01deg)

    67%
      border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%
      transform: translate3d(0,-3px,0) rotateZ(0.01deg)