[data-theme="dark"]
  .account
    display: flex
    align-items: center
    justify-content: center
    position: relative

    .account-info
      display: flex
      align-items: flex-end
      flex-direction: column
      justify-content: center
      margin-right: 16px

      .group
        color: $clr-8e

    .account-btn
      display: flex
      gap: 4px
      align-items: center
      justify-content: center
      cursor: pointer
      transition: $transition
      position: relative

      .account-drop
        &::before
          content: ''
          position: absolute
          width: 100%
          height: 100%
          top: 0
          left: 0
          display: block
          z-index: 1

      .account-avatar
        border-radius: 38px
        overflow: hidden
        display: flex
        align-items: center
        justify-content: center
        width: 38px
        height: 38px

        img
          width: 38px
          height: 38px
          object-fit: cover

      .account-arrow
        display: flex
        align-items: center
        justify-content: center
        transition: $transition

        i
          transition: $transition
          opacity: 0.5

        &.show
          i
            opacity: 1
            transform: rotate(180deg)

      &:hover
        .account-arrow
          i
            opacity: 1

      a
        text-decoration: none
        z-index: 2

        &:after
          bottom: 0
          content: ""
          left: 0
          position: absolute
          right: 0
          top: 0

    .separator
      display: flex
      align-items: center
      justify-content: center
      margin-right: 24px

      &:before
        content: ''
        width: 1px
        height: 20px
        background: $clr-2e
        position: relative
        display: block

    .dropdown
      background: $clr-26
      border: 1px solid $clr-2e
      border-radius: $border-radius
      -webkit-box-shadow: 0 4px 16px rgba(0, 0, 0, .4)
      box-shadow: 0 4px 16px rgba(0, 0, 0, .4)
      display: block
      padding: 10px
      position: absolute
      right: 0
      top: 56px
      width: 220px
      z-index: 999
      cursor: default

      .menu-item
        background: $clr-26
        border-radius: $border-radius
        transition: $transition
        position: relative

        &.admin-mob
          display: none

        a
          display: flex
          align-items: center
          padding: 12px
          text-decoration: none
          opacity: 0.5
          transition: $transition

          .menu-icon
            margin-right: 12px

        &:hover
          background: $clr-black

        &:active, &.active
          background: $clr-black

          a
            opacity: 1

        &.disabled
          opacity: 0.5 !important

          a
            opacity: 0.5

[data-theme="light"]
  .account
    display: flex
    align-items: center
    justify-content: center
    position: relative

    .account-info
      display: flex
      align-items: flex-end
      flex-direction: column
      justify-content: center
      margin-right: 16px

      .group
        color: $clr-8e

    .account-btn
      display: flex
      gap: 4px
      align-items: center
      justify-content: center
      cursor: pointer
      transition: $transition
      position: relative

      .account-drop
        &::before
          content: ''
          position: absolute
          width: 100%
          height: 100%
          top: 0
          left: 0
          display: block
          z-index: 1

      .account-avatar
        border-radius: 38px
        overflow: hidden
        display: flex
        align-items: center
        justify-content: center
        width: 38px
        height: 38px

        img
          width: 38px
          height: 38px
          object-fit: cover

      .account-arrow
        display: flex
        align-items: center
        justify-content: center
        transition: $transition

        i
          transition: $transition
          opacity: 0.5

        &.show
          i
            opacity: 1
            transform: rotate(180deg)

      &:hover
        .account-arrow
          i
            opacity: 1

      a
        text-decoration: none
        z-index: 2

        &:after
          bottom: 0
          content: ""
          left: 0
          position: absolute
          right: 0
          top: 0

    .separator
      display: flex
      align-items: center
      justify-content: center
      margin-right: 24px

      &:before
        content: ''
        width: 1px
        height: 20px
        background: $clr-e2
        position: relative
        display: block

    .dropdown
      background: $clr-white
      border: 1px solid $clr-df
      border-radius: $border-radius
      -webkit-box-shadow: 0 4px 16px rgba(0, 0, 0, .2)
      box-shadow: 0 4px 16px rgba(0, 0, 0, .2)
      display: block
      padding: 10px
      position: absolute
      right: 0
      top: 56px
      width: 220px
      z-index: 999
      cursor: default

      .menu-item
        background: $clr-white
        border-radius: $border-radius
        transition: $transition
        position: relative

        &.admin-mob
          display: none

        a
          display: flex
          align-items: center
          padding: 12px
          text-decoration: none
          opacity: 1
          transition: $transition

          .menu-icon
            margin-right: 12px

        &:hover
          background: $clr-f5

          a
            opacity: 1

        &:active, &.active
          background: $clr-f5
          opacity: 1

          a
            opacity: 1

        &.disabled
          opacity: 0.5 !important

          a
            opacity: 0.5