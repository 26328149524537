@import './fonts'
@import './variables'
@import './components/all'
@import './responsive/tablet'

*:focus
  outline: none

[data-theme="dark"] *
  --scrollbar-foreground: #2E2E2E
  --scrollbar-background: #1e1e1e
  --scrollbar-size: 16px
  scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background)

  &::-webkit-scrollbar
    width: var(--scrollbar-size)
    height: var(--scrollbar-size)

  &::-webkit-scrollbar-thumb
    border-radius: 10px
    border: 6px solid #1e1e1e
    background: var(--scrollbar-foreground)

  &::-webkit-scrollbar-track
    border-radius: 0px
    background: var(--scrollbar-background)

[data-theme="light"] *
  --scrollbar-foreground: #E2E2E2
  --scrollbar-background: #fff
  --scrollbar-size: 16px
  scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background)

  &::-webkit-scrollbar
    width: var(--scrollbar-size)
    height: var(--scrollbar-size)

  &::-webkit-scrollbar-thumb
    border-radius: 10px
    border: 6px solid #fff
    background: var(--scrollbar-foreground)

  &::-webkit-scrollbar-track
    border-radius: 0px
    background: var(--scrollbar-background)

a, abbr, acronym, address, applet, article, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, form, h1, h2, h3, h4, h5, h6, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video
  margin: 0
  padding: 0
  vertical-align: baseline

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section
  display: block

[data-theme="dark"]
  body
    background-color: $background
    color: $clr-white
    font-family: RFDewi, sans-serif
    margin: 0
    outline: 0
  hr
    background-color: $clr-2e
    border: 0
    height: 2px

[data-theme="light"]
  body
    background-color: $background-wht
    color: $clr-black
    font-family: RFDewi, sans-serif
    margin: 0
    outline: 0
  hr
    background-color: $clr-e2
    border: 0
    height: 2px

ol, ul
  list-style: none

table
  border-collapse: collapse
  border-spacing: 0

html
  margin-left: calc(100vw - 100% - 16px)
  margin-right: 0px
  font-size: 16px
  height: 100%

a:active, a:focus, a:hover
  outline: 0

.hidden
  display: none

.container
  margin-left: auto
  margin-right: auto
  padding-left: calc($bs-gutter-x * .5)
  padding-right: calc($bs-gutter-x * .5)
  width: $container-width

@media (min-width: 300px)
  .container
    width: 100%
    max-width: 100%
    box-sizing: border-box

  html
    margin-left: calc(100vw - 100% - 0px)

@media (min-width: 576px)
  .container
    max-width: 540px

@media (min-width: 768px)
  .container
    max-width: 720px

@media (min-width: 1001px)
  .container
    max-width: 960px

@media (min-width: 1201px)
  .container
    max-width: 1160px

  html
    margin-left: calc(100vw - 100% - 16px)

@media (min-width: 1341px)
  .container
    max-width: 1296px