// Размеры шрифтов
[data-theme="dark"]
  h1
    font-family: 'RF Dewi', sans-serif!important
    font-weight: 700
    font-size: 1.5rem
    line-height: 36px
    color: $clr-white

  h2
    font-family: 'RF Dewi', sans-serif!important
    font-weight: 700
    font-size: 1.25rem
    line-height: 30px
    color: $clr-white

  h3
    font-family: 'RF Dewi', sans-serif!important
    font-weight: 600
    font-size: 1rem
    line-height: 24px
    color: $clr-white

  h4
    font-family: 'RF Dewi', sans-serif!important
    font-weight: 600
    font-size: 1.15rem
    line-height: 24px
    color: $clr-white

  h1, h2, h3, h4, p, span, div, li, a, button
    &.body-1
      font-family: 'RF Dewi', sans-serif!important
      font-weight: 400
      font-size: .875rem
      line-height: 24px
      color: $clr-white

    &.body-2
      font-family: 'RF Dewi', sans-serif!important
      font-weight: 400
      font-size: .75rem
      line-height: 20px
      color: $clr-white

    &.caption-1
      font-family: 'RF Dewi', sans-serif!important
      font-weight: 400
      font-size: 1.125rem
      line-height: 24px
      color: $clr-white

    &.caption-2
      font-family: 'RF Dewi', sans-serif!important
      font-weight: 400
      font-size: 0.85rem
      line-height: 24px
      color: $clr-white

    &.caption-3
      font-family: 'RF Dewi', sans-serif!important
      font-weight: 400
      font-size: .75rem
      line-height: 20px
      color: $clr-white

    &.caption-4
      font-family: 'RF Dewi', sans-serif!important
      font-weight: 400
      font-size: .65rem
      line-height: 18px
      color: $clr-white

    &.caption-5
      font-family: 'RF Dewi', sans-serif!important
      font-weight: 400
      font-size: .375rem
      line-height: 12px
      color: $clr-white

    &.button-1
      font-family: 'RF Dewi', sans-serif!important
      font-weight: 700
      font-size: .875rem
      line-height: 20px
      color: $clr-white

    &.button-2
      font-family: 'RF Dewi', sans-serif!important
      font-weight: 600
      font-size: .875rem
      line-height: 24px
      color: $clr-white

    &.button-3
      font-family: 'RF Dewi', sans-serif!important
      font-weight: 600
      font-size: .75rem
      line-height: 20px
      color: $clr-white

[data-theme="light"]
  h1
    font-family: 'RF Dewi', sans-serif!important
    font-weight: 700
    font-size: 1.5rem
    line-height: 36px
    color: $clr-black

  h2
    font-family: 'RF Dewi', sans-serif!important
    font-weight: 700
    font-size: 1.25rem
    line-height: 30px
    color: $clr-black

  h3
    font-family: 'RF Dewi', sans-serif!important
    font-weight: 600
    font-size: 1rem
    line-height: 24px
    color: $clr-black

  h1, h2, h3, p, span, div, li, a, button
    &.body-1
      font-family: 'RF Dewi', sans-serif!important
      font-weight: 400
      font-size: .875rem
      line-height: 24px
      color: $clr-black

    &.body-2
      font-family: 'RF Dewi', sans-serif!important
      font-weight: 400
      font-size: .75rem
      line-height: 20px
      color: $clr-white

    &.caption-1
      font-family: 'RF Dewi', sans-serif!important
      font-weight: 400
      font-size: 1.125rem
      line-height: 24px
      color: $clr-black

    &.caption-2
      font-family: 'RF Dewi', sans-serif!important
      font-weight: 400
      font-size: 0.85rem
      line-height: 24px
      color: $clr-black

    &.caption-3
      font-family: 'RF Dewi', sans-serif!important
      font-weight: 400
      font-size: .75rem
      line-height: 20px
      color: $clr-black

    &.caption-4
      font-family: 'RF Dewi', sans-serif!important
      font-weight: 400
      font-size: .65rem
      line-height: 18px
      color: $clr-black

    &.caption-5
      font-family: 'RF Dewi', sans-serif!important
      font-weight: 400
      font-size: .375rem
      line-height: 12px
      color: $clr-black

    &.button-1
      font-family: 'RF Dewi', sans-serif!important
      font-weight: 700
      font-size: .875rem
      line-height: 20px
      color: $clr-black

    &.button-2
      font-family: 'RF Dewi', sans-serif!important
      font-weight: 600
      font-size: .875rem
      line-height: 24px
      color: $clr-black

    &.button-3
      font-family: 'RF Dewi', sans-serif!important
      font-weight: 600
      font-size: .75rem
      line-height: 20px
      color: $clr-black