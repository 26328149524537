[data-theme="dark"]
  .users
    .users-block
      grid-gap: 10px
      display: grid
      grid-template-columns: repeat(4, 1fr)
      grid-template-rows: auto
      position: relative

      .users-block-item
        grid-column: span 1
        width: 100%
        background: transparent
        border-radius: $border-radius
        transition: $transition
        position: relative
        overflow: hidden

        .user-info
          padding: 12px 16px
          display: flex
          align-items: center
          gap: 16px

          .user-avatar
            border-radius: 50px
            overflow: hidden
            display: flex
            align-items: center
            justify-content: center
            width: 50px
            height: 50px
            flex-shrink: 0

            img
              width: 50px
              height: 50px
              object-fit: cover

          .user-name
            width: 100%
            overflow: hidden

            a
              text-decoration: none

              &:after
                bottom: 0
                content: ""
                left: 0
                position: absolute
                right: 0
                top: 0

              h3
                flex-shrink: 0
                width: 100%
                white-space: nowrap
                text-overflow: ellipsis
                overflow: hidden

            p
              color: $clr-8e

        &:hover
          background: $clr-26

        &:active, &.active
          background: $clr-26

          .user-info
            opacity: 1

        &:disabled, &.disabled
          background: $clr-black
          pointer-events: none
          opacity: 0.5

      .info
        grid-column: 1 / span 4
        color: $clr-8e


    .user-edit
      display: flex
      flex-direction: column
      gap: 30px

      .user-info
        padding: 0
        display: flex
        align-items: center
        gap: 16px

        .user-avatar
          border-radius: 50px
          overflow: hidden
          display: flex
          align-items: center
          position: relative
          justify-content: center
          width: 80px
          height: 80px
          flex-shrink: 0

          img
            width: 80px
            height: 80px
            object-fit: cover

          .btn-avatar
            position: absolute
            top: 0
            left: 0
            right: 0
            bottom: 0
            width: 100%
            height: 100%

            &:after
              content: ''
              background: #1E1E1E
              position: absolute
              top: 0
              left: 0
              width: 100%
              height: 100%
              opacity: 0.5
              z-index: 0

            button
              background: transparent
              z-index: 2
              position: absolute
              top: 0
              left: 0
              right: 0
              bottom: 0

        .user-name
          width: 100%
          display: flex
          flex-direction: column
          gap: 6px

          a
            text-decoration: none

            &:after
              bottom: 0
              content: ""
              left: 0
              position: absolute
              right: 0
              top: 0

          p
            color: $clr-8e

      form
        h3
          margin-bottom: 24px

        .grid-input
          grid-gap: 24px
          display: grid
          grid-template-columns: repeat(2, 1fr)
          grid-template-rows: auto
          margin-bottom: 36px

          label
            position: relative

            p
              margin-bottom: 12px
              color: $clr-8e

            input
              box-sizing: border-box
              width: 100%

            .grid-input-pass
              position: relative

            .password-toggle
              cursor: pointer
              background: none
              border: none
              position: absolute
              right: 0
              top: 0
              margin: 0
              padding: 12px
              opacity: 0.5
              transition: $transition

              &:hover
                opacity: 1

              &:active
                opacity: 0.8


        .button-block
          display: flex
          margin-top: 40px
          align-items: center
          gap: 40px
          justify-content: space-between

          .delete-user-btn
            display: flex
            align-items: center
            gap: 12px
            color: $clr-white
            opacity: 0.5
            transition: $transition
            cursor: pointer
            border-radius: $border-radius
            background: $clr-26
            padding: 12px 20px

            i
              &:before
                width: 20px
                height: 20px
                background-size: 20px

            &:hover
              opacity: 1

            &:active
              opacity: 0.8

[data-theme="light"]
  .users
    .users-block
      grid-gap: 10px
      display: grid
      grid-template-columns: repeat(4, 1fr)
      grid-template-rows: auto
      position: relative

      .users-block-item
        grid-column: span 1
        width: 100%
        background: transparent
        border-radius: $border-radius
        transition: $transition
        position: relative
        overflow: hidden

        .user-info
          padding: 12px 16px
          display: flex
          align-items: center
          gap: 16px

          .user-avatar
            border-radius: 50px
            overflow: hidden
            display: flex
            align-items: center
            justify-content: center
            width: 50px
            height: 50px
            flex-shrink: 0

            img
              width: 50px
              height: 50px
              object-fit: cover

          .user-name
            width: 100%
            overflow: hidden

            a
              text-decoration: none

              &:after
                bottom: 0
                content: ""
                left: 0
                position: absolute
                right: 0
                top: 0

              h3
                flex-shrink: 0
                width: 100%
                white-space: nowrap
                text-overflow: ellipsis
                overflow: hidden

            p
              color: $clr-8e

        &:hover
          background: #F5F5F5

        &:active, &.active
          background: #F5F5F5

          .user-info
            opacity: 1

        &:disabled, &.disabled
          background: #F5F5F5
          pointer-events: none
          opacity: 0.5

      .info
        grid-column: 1 / span 4
        color: $clr-8e


    .user-edit
      display: flex
      flex-direction: column
      gap: 30px

      .user-info
        padding: 0
        display: flex
        align-items: center
        gap: 16px

        .user-avatar
          border-radius: 50px
          overflow: hidden
          display: flex
          align-items: center
          position: relative
          justify-content: center
          width: 80px
          height: 80px
          flex-shrink: 0

          img
            width: 80px
            height: 80px
            object-fit: cover

          .btn-avatar
            position: absolute
            top: 0
            left: 0
            right: 0
            bottom: 0
            width: 100%
            height: 100%

            &:after
              content: ''
              background: #fff
              position: absolute
              top: 0
              left: 0
              width: 100%
              height: 100%
              opacity: 0.5
              z-index: 0

            button
              background: transparent
              z-index: 2
              position: absolute
              top: 0
              left: 0
              right: 0
              bottom: 0
              opacity: 1

              i
                opacity: 1

              &:hover
                opacity: 0.5

        .user-name
          width: 100%
          display: flex
          flex-direction: column
          gap: 6px

          a
            text-decoration: none

            &:after
              bottom: 0
              content: ""
              left: 0
              position: absolute
              right: 0
              top: 0

          p
            color: $clr-8e

      form
        h3
          margin-bottom: 24px

        .grid-input
          grid-gap: 24px
          display: grid
          grid-template-columns: repeat(2, 1fr)
          grid-template-rows: auto
          margin-bottom: 36px

          label
            position: relative

            p
              margin-bottom: 12px
              color: $clr-8e

            input
              box-sizing: border-box
              width: 100%

            .grid-input-pass
              position: relative

            .password-toggle
              cursor: pointer
              background: none
              border: none
              position: absolute
              right: 0
              top: 0
              margin: 0
              padding: 12px
              opacity: 0.5
              transition: $transition

              &:hover
                opacity: 1

              &:active
                opacity: 0.8

        .button-block
          display: flex
          margin-top: 40px
          align-items: center
          gap: 40px
          justify-content: space-between

          .delete-user-btn
            display: flex
            align-items: center
            gap: 12px
            color: $clr-black
            opacity: 1
            transition: $transition
            cursor: pointer
            border-radius: $border-radius
            background: $clr-f5
            padding: 12px 20px

            i
              &:before
                width: 20px
                height: 20px
                background-size: 20px

            &:hover
              opacity: 0.8

            &:active
              opacity: 0.8