[data-theme="dark"]
  aside
    width: 196px
    position: sticky
    float: left
    height: 1px
    margin-right: 12px
    top: auto

  .menu-item
    border-radius: $border-radius
    transition: $transition
    position: relative
    overflow: hidden

    a
      display: flex
      align-items: center
      padding: 12px
      text-decoration: none
      opacity: 0.5
      transition: $transition

      .menu-icon
        margin-right: 12px

      &:hover
        background: $clr-26

      &:active, &.active
        background: $clr-26
        opacity: 1

    &:disabled, &.disabled
      background: transparent!important
      pointer-events: none
      opacity: 0.5

      a
        opacity: 0.25

    &.mob-item
      display: none

  .mob-menu
    display: none
    align-items: center
    justify-content: center
    position: absolute
    width: 100%
    height: 0
    bottom: -100px
    background: #1e1e1e
    border-top: 1px solid #2E2E2E
    border-bottom: 1px solid #2E2E2E
    border-radius: 10px 10px 0 0
    box-shadow: 0 -10px 10px rgba(0, 0, 0, 0.2)
    padding-left: 0.75rem
    padding-right: 0.75rem
    padding-top: 20px
    padding-bottom: 20px
    box-sizing: border-box
    z-index: 1
    transition: $transition

    .menu-wrapper
      max-width: 546px
      width: 100%

    &.active
      height: auto
      bottom: 56px

  .sticky-aside
    position: fixed
    top: 24px
    width: inherit

[data-theme="light"]
  aside
    width: 196px
    position: sticky
    float: left
    height: 1px
    margin-right: 12px
    top: auto

  .menu-item
    border-radius: $border-radius
    transition: $transition
    position: relative
    overflow: hidden

    a
      display: flex
      align-items: center
      padding: 12px
      text-decoration: none
      opacity: 1
      transition: $transition

      .menu-icon
        margin-right: 12px

      &:hover
        background: $clr-f5
        opacity: 1

      &:active, &.active
        background: $clr-f5
        opacity: 1

    &:disabled, &.disabled
      background: transparent!important
      pointer-events: none
      opacity: 0.5

      a
        opacity: 0.25

    &.mob-item
      display: none

  .mob-menu
    display: none
    position: absolute
    width: 100%
    height: 0
    bottom: -50px
    background: #1e1e1e
    border-top: 1px solid #2E2E2E
    border-bottom: 1px solid #2E2E2E
    border-radius: 10px 10px 0 0
    box-shadow: 0 -4px 16px rgba(0, 0, 0, 0.4)
    padding-left: 0.75rem
    padding-right: 0.75rem
    padding-top: 20px
    padding-bottom: 20px
    box-sizing: border-box
    z-index: 1
    transition: $transition

    &.active
      height: auto
      bottom: 56px

  .sticky-aside
    position: fixed
    top: 24px
    width: inherit