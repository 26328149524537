[data-theme="dark"]
  .checkbox-wrapper
    display: flex
    align-items: center
    gap: 12px
    width: auto
    height: auto
    cursor: pointer
    position: relative
    grid-column: span 2

    input[type="checkbox"]
      display: none

    input[type="checkbox"]:checked
      & + .checkbox-slider
        background-color: $clr-26
        opacity: 1
        .checkbox-knob
          &:before
            opacity: 1

    .checkbox-slider
      background-color: $clr-26
      position: relative
      border-radius: 100px
      top: 0
      left: 0
      width: 45px
      height: 25px
      opacity: 0.5
      transition: $transition

    .checkbox-knob
      position: absolute
      transition: $transition

    input[type="checkbox"]:checked
      & + .checkbox-slider
        .checkbox-knob
          left: calc(100% - 17px - 4px)

    .checkbox-knob
      width: calc(25px - 8px)
      height: calc(25px - 8px)
      border-radius: 50%
      left: 4px
      top: 4px
      background-color: $clr-white
      background-repeat: no-repeat
      background-size: 80%
      background-position: center
      position: relative
      &:before
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 17 16'%3E%3Cpath stroke='%23000' stroke-linecap='round' stroke-linejoin='round' d='M4.7 8.362 7.233 10.7 12.3 5'/%3E%3C/svg%3E")
        height: 100%
        opacity: 0
        position: absolute
        transform: translate(1px, 1px)
        transition: $transition
        width: 100%
        z-index: 1

    input[type="checkbox"]:checked
      & ~ span
        color: $clr-white

    span
      color: $clr-8e
      transition: $transition

    &:hover

      .checkbox-slider
        opacity: 1

      span
        color: $clr-white

[data-theme="light"]
  .checkbox-wrapper
    display: flex
    align-items: center
    gap: 12px
    width: auto
    height: auto
    cursor: pointer
    position: relative
    grid-column: span 2

    input[type="checkbox"]
      display: none

    input[type="checkbox"]:checked
      & + .checkbox-slider
        background-color: $clr-f5
        opacity: 1
        .checkbox-knob
          &:before
            opacity: 1

    .checkbox-slider
      background-color: $clr-f5
      position: relative
      border-radius: 100px
      top: 0
      left: 0
      width: 45px
      height: 25px
      opacity: 0.5
      transition: $transition

    .checkbox-knob
      position: absolute
      transition: $transition

    input[type="checkbox"]:checked
      & + .checkbox-slider
        .checkbox-knob
          left: calc(100% - 17px - 4px)

    .checkbox-knob
      width: calc(25px - 8px)
      height: calc(25px - 8px)
      border-radius: 50%
      left: 4px
      top: 4px
      background-color: $clr-black
      background-repeat: no-repeat
      background-size: 80%
      background-position: center
      position: relative
      &:before
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 17 16'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' d='M4.7 8.362 7.233 10.7 12.3 5'/%3E%3C/svg%3E")
        height: 100%
        opacity: 0
        position: absolute
        transform: translate(1px, 1px)
        transition: $transition
        width: 100%
        z-index: 1

    input[type="checkbox"]:checked
      & ~ span
        color: $clr-black

    span
      color: $clr-8e
      transition: $transition

    &:hover

      .checkbox-slider
        opacity: 1

      span
        color: $clr-black
