@media screen and (min-width: 1200px) and (max-width: 1340px)
  [data-theme="dark"] .users
    .users-block
      grid-template-columns: repeat(3, 1fr)

  [data-theme="light"] .users
    .users-block
      grid-template-columns: repeat(3, 1fr)

  .seller
    .seller-amount
      display: flex
      align-items: center
      justify-content: space-between

@media screen and (min-width: 1000px) and (max-width: 1200px)

  [data-theme="dark"] aside
    width: 48px

  [data-theme="light"] aside
    width: 48px

  [data-theme="dark"] .sticky-seller
    position: sticky
    top: 0
    width: inherit

  [data-theme="light"] .sticky-seller
    position: sticky
    top: 0
    width: inherit

  .menu-item
    a
      .menu-icon
        margin-right: 0px

      span
        display: none

  main
    .content-orders
      grid-column: span 10
      width: 100%
      order: 2

    .right-block
      grid-column: span 10
      width: 100%
      order: 1

  [data-theme="dark"] .seller
    align-items: center
    justify-content: space-between
    flex-direction: row

    .seller-info
      flex-direction: row
      align-items: flex-start
      margin-bottom: 0

    .seller-amount
      align-items: flex-start
      justify-content: space-between
      flex-direction: row
      gap: 30px

  [data-theme="light"] .seller
    align-items: center
    justify-content: space-between
    flex-direction: row

    .seller-info
      flex-direction: row
      align-items: flex-start
      margin-bottom: 0

    .seller-amount
      align-items: flex-start
      justify-content: space-between
      flex-direction: row
      gap: 30px

  [data-theme="dark"] .users
    .users-block
      grid-template-columns: repeat(3, 1fr)

  [data-theme="light"] .users
    .users-block
      grid-template-columns: repeat(3, 1fr)

  .account
    .dropdown
      .menu-item
        a
          span
            display: inherit

  [data-theme="dark"] .artist-list
    overflow-x: scroll

    &::-webkit-scrollbar
      display: none

    ul
      flex-wrap: nowrap

      li
        flex-shrink: 0

  [data-theme="light"] .artist-list
    overflow-x: scroll

    &::-webkit-scrollbar
      display: none

    ul
      flex-wrap: nowrap

      li
        flex-shrink: 0

@media screen and (min-width: 768px) and (max-width: 1000px)
  [data-theme="dark"] aside
    width: 48px

  [data-theme="light"] aside
    width: 48px

  [data-theme="dark"] .sticky-seller
    position: sticky
    top: 0
    width: inherit

  [data-theme="light"] .sticky-seller
    position: sticky
    top: 0
    width: inherit

  .menu-item
    a
      .menu-icon
        margin-right: 0px

      span
        display: none

  .account
    .dropdown
      .menu-item
        a
          span
            display: inherit

  main
    .content-orders
      grid-column: span 10
      width: 100%
      order: 2

    .right-block
      grid-column: span 10
      width: 100%
      order: 1

  [data-theme="dark"] .seller
    align-items: center
    justify-content: space-between
    flex-direction: row

    .seller-info
      flex-direction: row
      align-items: flex-start
      margin-bottom: 0

    .seller-amount
      align-items: flex-start
      justify-content: space-between
      flex-direction: row
      gap: 30px

  [data-theme="light"] .seller
    align-items: center
    justify-content: space-between
    flex-direction: row

    .seller-info
      flex-direction: row
      align-items: flex-start
      margin-bottom: 0

    .seller-amount
      align-items: flex-start
      justify-content: space-between
      flex-direction: row
      gap: 30px

  [data-theme="dark"] .users
    .users-block
      grid-template-columns: repeat(2, 1fr)

  [data-theme="light"] .users
    .users-block
      grid-template-columns: repeat(2, 1fr)

  .content-users
    .head-selection
      flex-direction: column
      align-items: flex-start

      .head-selection-line
        order: 2

      .search-block
        order: 1
        width: 100%

        input
          width: 100%

  [data-theme="dark"] .artist-list
    overflow-x: scroll

    &::-webkit-scrollbar
      display: none

    ul
      flex-wrap: nowrap

      li
        flex-shrink: 0

  [data-theme="light"] .artist-list
    overflow-x: scroll

    &::-webkit-scrollbar
      display: none

    ul
      flex-wrap: nowrap

      li
        flex-shrink: 0

@media screen and (max-width: 768px)
  html
    font-size: 15px

  main
    padding-left: 0px

  [data-theme="dark"] .sticky-seller
    position: sticky
    top: 0
    width: inherit

  [data-theme="light"] .sticky-seller
    position: sticky
    top: 0
    width: inherit

  [data-theme="dark"] .sign
    .auth-block
      padding: 30px

      .sign-block
        width: 100%
        box-sizing: border-box

        .sign-form
          form
            min-width: 100%
            gap: 12px

            .label-blind
              gap: 12px
              flex-direction: column

        .sign-form-link
          flex-direction: inherit

          a
            padding: 12px 0

        .sign-soc
          margin-top: 30px

  [data-theme="light"] .sign
    .auth-block
      padding: 30px

      .sign-block
        width: 100%
        box-sizing: border-box

        .sign-form
          form
            min-width: 100%
            gap: 12px

            .label-blind
              gap: 12px
              flex-direction: column

        .sign-form-link
          flex-direction: inherit

          a
            padding: 12px 0

        .sign-soc
          margin-top: 30px

  [data-theme="dark"] .sticky-aside
    position: sticky
    top: 0
    width: 100%

  [data-theme="dark"] aside
    position: fixed
    z-index: 2
    padding: 0
    border-top: 1px solid #2E2E2E
    background: #1E1E1E
    bottom: 0
    width: 100%
    left: 0
    margin: 0
    float: initial
    height: auto

    nav
      display: flex
      align-items: center
      justify-content: space-between
      max-width: 540px
      margin-left: auto
      margin-right: auto
      padding-left: 0.75rem
      padding-right: 0.75rem
      box-sizing: border-box
      width: 100%
      z-index: 2
      position: relative
      background: #1e1e1e

      .menu-item
        width: auto
        border-radius: 0
        z-index: 2

        &.admin
          display: none

        &.shop
          display: initial

        a
          flex-direction: column
          align-items: center
          justify-content: center
          padding: 6px 0
          background: transparent !important

          .menu-icon
            margin: 0

          span
            font-size: 0.65rem
            font-weight: 400

        &.mob-item
          display: initial

    .mob-menu
      display: flex

  [data-theme="light"] .sticky-aside
    position: sticky
    top: 0
    width: 100%

  [data-theme="light"] aside
    position: fixed
    z-index: 2
    padding: 0
    border-top: 1px solid #e2e2e2
    background: #fff
    bottom: 0
    width: 100%
    left: 0
    margin: 0
    float: initial
    height: auto

    nav
      display: flex
      align-items: center
      justify-content: space-between
      max-width: 540px
      margin-left: auto
      margin-right: auto
      padding-left: 0.75rem
      padding-right: 0.75rem
      box-sizing: border-box
      width: 100%

      .menu-item
        width: auto
        border-radius: 0

        &.admin
          display: none

        &.shop
          display: initial

        &.disabled
          opacity: 0.7

        a
          flex-direction: column
          align-items: center
          justify-content: center
          padding: 6px 0px
          background: transparent !important
          opacity: 0.8

          .menu-icon
            margin: 0

          span
            font-size: 0.65rem
            font-weight: 700

        &.mob-item
          display: initial

    .mob-menu
      display: flex

  [data-theme="dark"] .account
    .account-info
      display: none

    .dropdown
      .menu-item
        &.admin-mob
          display: initial

  [data-theme="light"] .account
    .account-info
      display: none

    .dropdown
      .menu-item
        &.admin-mob
          display: initial

  main

    .content-orders
      grid-column: span 10
      width: 100%
      order: 2

    .right-block
      grid-column: span 10
      width: 100%
      order: 1

  .main
    margin-top: 30px

  [data-theme="dark"] .seller
    align-items: flex-start
    justify-content: space-between
    flex-direction: column

    .seller-info
      flex-direction: row
      align-items: flex-start
      margin-bottom: 26px

    .seller-amount
      align-items: flex-start
      justify-content: space-between
      flex-direction: row
      gap: 30px
      width: 100%

      .total-item

        &:hover
          opacity: 1

        &:active
          opacity: 1

  [data-theme="light"] .seller
    align-items: flex-start
    justify-content: space-between
    flex-direction: column

    .seller-info
      flex-direction: row
      align-items: flex-start
      margin-bottom: 26px

    .seller-amount
      align-items: flex-start
      justify-content: space-between
      flex-direction: row
      gap: 30px
      width: 100%

      .total-item

        &:hover
          opacity: 1

        &:active
          opacity: 1

  [data-theme="dark"] .users
    .users-block
      grid-template-columns: repeat(2, 1fr)

  [data-theme="light"] .users
    .users-block
      grid-template-columns: repeat(2, 1fr)

  .content-users
    .head-selection
      flex-direction: column
      align-items: flex-start

      .head-selection-line
        order: 2

      .search-block
        order: 1
        width: 100%

        input
          width: 100%

  [data-theme="dark"] .setting-page
    .setting-edit
      form
        .grid-input
          grid-gap: 12px 24px
          display: grid
          grid-template-columns: repeat(1, 1fr)
          grid-template-rows: auto
          margin-bottom: 24px

          &.grid-three
            grid-template-columns: repeat(1, 1fr)

          .checkbox-wrapper
            grid-column: span 1

        .button-block
          flex-direction: column
          gap: 30px

          button
            width: 100%

  [data-theme="light"] .setting-page
    .setting-edit
      form
        .grid-input
          grid-gap: 12px 24px
          display: grid
          grid-template-columns: repeat(1, 1fr)
          grid-template-rows: auto
          margin-bottom: 24px

          &.grid-three
            grid-template-columns: repeat(1, 1fr)

          .checkbox-wrapper
            grid-column: span 1

        .button-block
          flex-direction: column
          gap: 30px

          button
            width: 100%

  [data-theme="dark"] .users
    .user-edit
      form
        .grid-input
          grid-gap: 24px
          display: grid
          grid-template-columns: repeat(1, 1fr)
          grid-template-rows: auto

          .checkbox-wrapper
            grid-column: span 1

        .button-block
          flex-direction: column
          gap: 30px

          button
            width: 100%

          .delete-user-btn
            width: 100%
            box-sizing: border-box
            justify-content: center

  [data-theme="light"] .users
    .user-edit
      form
        .grid-input
          grid-gap: 24px
          display: grid
          grid-template-columns: repeat(1, 1fr)
          grid-template-rows: auto

          .checkbox-wrapper
            grid-column: span 1

        .button-block
          flex-direction: column
          gap: 30px

          button
            width: 100%

          .delete-user-btn
            width: 100%
            box-sizing: border-box
            justify-content: center

  [data-theme="dark"] .bg-block
    display: none

  [data-theme="light"] .bg-block
    display: none

  [data-theme="dark"] .sign
    height: 100%

    .auth-block
      width: 100%
      height: 100%
      max-width: 100%
      border-radius: 0
      position: relative
      border: none
      box-shadow: none

  [data-theme="light"] .sign
    height: 100%

    .auth-block
      width: 100%
      height: 100%
      max-width: 100%
      border-radius: 0
      position: relative
      border: none
      box-shadow: none

  [data-theme="dark"] footer
    padding-bottom: 76px

  [data-theme="light"] footer
    padding-bottom: 76px

  [data-theme="dark"] .artist-list
    overflow-x: scroll

    &::-webkit-scrollbar
      display: none

    ul
      flex-wrap: nowrap

      li
        flex-shrink: 0

  [data-theme="light"] .artist-list
    overflow-x: scroll

    &::-webkit-scrollbar
      display: none

    ul
      flex-wrap: nowrap

      li
        flex-shrink: 0

@media screen and (max-width: 576px)
  html
    font-size: 14px

  header
    .left-block
      .header-logo
        .icon-logo
          &::before
            width: 150px
            background-size: 150px

  .content-users
    .head-selection
      .head-selection-line
        width: 100%

    .head-selection
      .head-selection-item
        &.back
          width: 100%

  [data-theme="dark"]
    .orders
      .order-list
        .order-block
          gap: 10px
          flex-wrap: nowrap
          padding: 16px 16px
          margin-top: 0
          justify-content: space-between

          .order-block-info
            display: flex
            flex-direction: column
            gap: 10px
            flex-shrink: 0
            max-width: 230px

            .order-block-list
              max-width: 230px

              p
                overflow: hidden
                white-space: nowrap
                text-overflow: ellipsis

            .order-block-title
              display: flex
              align-items: center
              gap: 10px

              .order-block-sum
                flex-shrink: 0

              .order-block-other
                gap: 8px
                display: flex
                flex-shrink: 0

          .order-item-list
            display: flex
            flex-wrap: wrap
            gap: 8px
            align-items: flex-start
            margin-top: 9px
            justify-content: flex-end

            .order-item
              display: flex
              flex-direction: column
              gap: 6px
              align-items: center
              flex: 0 1 35px

              .order-item-img
                img
                  width: 35px !important
                  height: 35px !important
                  object-fit: cover

              .order-item-size
                display: flex
                align-items: center
                justify-content: center
                text-align: center
                white-space: nowrap
                overflow: hidden
                text-overflow: ellipsis
                width: 35px

                span
                  font-size: 8px
                  line-height: 14px
                  padding: 0 4px

  [data-theme="light"]
    .orders
      .order-list
        .order-block
          gap: 10px
          flex-wrap: nowrap
          padding: 16px 16px
          margin-top: 0
          justify-content: space-between

          .order-block-info
            display: flex
            flex-direction: column
            gap: 10px
            flex-shrink: 0
            max-width: 230px

            .order-block-list
              max-width: 230px

              p
                overflow: hidden
                white-space: nowrap
                text-overflow: ellipsis

            .order-block-title
              display: flex
              align-items: center
              gap: 10px

              .order-block-sum
                flex-shrink: 0

              .order-block-other
                gap: 8px
                display: flex
                flex-shrink: 0

          .order-item-list
            display: flex
            flex-wrap: wrap
            gap: 8px
            align-items: flex-start
            margin-top: 9px
            justify-content: flex-end

            .order-item
              display: flex
              flex-direction: column
              gap: 6px
              align-items: center
              flex: 0 1 35px

              .order-item-img
                img
                  width: 35px !important
                  height: 35px !important
                  object-fit: cover

              .order-item-size
                display: flex
                align-items: center
                justify-content: center
                text-align: center
                white-space: nowrap
                overflow: hidden
                text-overflow: ellipsis
                width: 35px

                span
                  font-size: 8px
                  line-height: 14px
                  padding: 0 4px

  [data-theme="dark"] .users
    .users-block
      grid-template-columns: repeat(1, 1fr)

  [data-theme="light"] .users
    .users-block
      grid-template-columns: repeat(1, 1fr)

  [data-theme="dark"] .function-block
    min-width: auto

  [data-theme="light"] .function-block
    min-width: auto

  [data-theme="dark"] .artist-list
    overflow-x: scroll

    &::-webkit-scrollbar
      display: none

    ul
      flex-wrap: nowrap

      li
        flex-shrink: 0

  [data-theme="light"] .artist-list
    overflow-x: scroll

    &::-webkit-scrollbar
      display: none

    ul
      flex-wrap: nowrap

      li
        flex-shrink: 0

  footer
    .container
      flex-direction: column