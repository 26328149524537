[data-theme="dark"]
  .orders
    .order-list
      border-radius: 10px
      overflow: hidden
      border: 1px solid $clr-2e

      .order-block
        padding: 20px 20px
        border-bottom: 1px solid $clr-2e
        margin-top: 0
        display: flex
        align-items: flex-start
        justify-content: space-between
        transition: $transition
        gap: 20px

        &:hover
          background: rgb(46 46 46 / 20%)

        &:last-child
          border-bottom: none

        .order-block-info
          display: flex
          flex-direction: column
          gap: 16px
          flex-shrink: 0

          .order-block-title
            display: flex
            align-blocks: center
            gap: 20px

            .order-block-other
              gap: 10px
              display: flex

              span
                text-transform: uppercase
                color: $clr-4e
                display: flex
                align-items: center

          .order-block-list
            display: flex
            gap: 4px
            flex-direction: column

            p
              color: $clr-8e

        .order-item-list
          display: flex
          gap: 8px
          align-items: flex-start
          margin-top: 9px
          flex-wrap: wrap
          justify-content: flex-end

          .order-item
            display: flex
            flex-direction: column
            gap: 8px
            align-items: center

            .order-item-img
              display: flex
              border-radius: 6px
              overflow: hidden

              a
                display: flex
                transition: $transition
                border-radius: 6px
                overflow: hidden

                &:hover
                  opacity: 0.8

                &:active
                  opacity: 0.5

                img
                  width: 50px
                  height: 50px
                  background: #fff
                  color: #fff

            .order-item-size
              display: flex
              align-items: center
              justify-content: center

              span
                background: $clr-2e
                padding: 0 5px
                border-radius: 2px

      &.noblock
        border: none

    .order-error
      span
        opacity: .5

    .info
      color: $clr-8e

[data-theme="light"]
  .orders
    .order-list
      border-radius: 10px
      overflow: hidden
      border: 1px solid $clr-e2

      .order-block
        padding: 20px 20px
        border-bottom: 1px solid $clr-e2
        margin-top: 0
        display: flex
        align-items: flex-start
        justify-content: space-between
        transition: $transition
        gap: 20px

        &:hover
          background: rgb(226 226 226 / 20%)

        &:last-child
          border-bottom: none

        .order-block-info
          display: flex
          flex-direction: column
          gap: 16px
          flex-shrink: 0

          .order-block-title
            display: flex
            align-blocks: center
            gap: 20px

            .order-block-other
              gap: 10px
              display: flex

              span
                text-transform: uppercase
                color: $clr-4e
                display: flex
                align-items: center

          .order-block-list
            display: flex
            gap: 4px
            flex-direction: column

            p
              color: $clr-8e

        .order-item-list
          display: flex
          gap: 8px
          align-items: flex-start
          margin-top: 9px
          flex-wrap: wrap
          justify-content: flex-end

          .order-item
            display: flex
            flex-direction: column
            gap: 8px
            align-items: center

            .order-item-img
              display: flex
              border-radius: 6px
              overflow: hidden

              a
                display: flex
                transition: $transition
                border-radius: 6px
                overflow: hidden

                &:hover
                  opacity: 0.8

                &:active
                  opacity: 0.5

                img
                  width: 50px
                  height: 50px
                  background: #fff
                  color: #fff

            .order-item-size
              display: flex
              align-items: center
              justify-content: center

              span
                background: $clr-f5
                padding: 0 5px
                border-radius: 2px

      &.noblock
        border: none

    .order-error
      span
        opacity: .5

    .info
      color: $clr-8e

