[data-theme="dark"]
  .button, button
    align-items: center
    appearance: none
    background: $clr-white
    border-radius: $border-radius
    border-width: 0
    color: $clr-black
    cursor: pointer
    display: flex
    font-size: 14px
    font-weight: 600
    justify-content: center
    line-height: 24px
    padding: 12px 32px
    text-align: center
    text-decoration: none
    white-space: nowrap
    transition: $transition

    &:hover
      background: $clr-e2
      color: $clr-black
      text-decoration: none
      outline: 0

    &:active
      background: $clr-8e
      color: $clr-black
      text-decoration: none
      outline: 0
      opacity: 0.8

    &:disabled
      opacity: .5
      pointer-events: none
      cursor: default

    &.medium
      padding: 10px 24px
      font-size: 14px
      font-weight: 600

    &.small
      padding: 8px 16px
      font-size: 14px
      line-height: 20px

    &.black
      background: $clr-black
      color: $clr-8e

      &:hover
        background: $clr-26
        color: $clr-8e
        text-decoration: none
        outline: 0

      &:active
        background: $clr-26
        color: $clr-white
        text-decoration: none
        outline: 0

    &.soc
      background: $clr-26
      padding: 12px
      color: $clr-8e

      i
        transition: $transition
        opacity: .5

      &:hover
        background: $clr-2e
        text-decoration: none
        outline: 0

        i
          transition: $transition
          opacity: 1

      &:active
        background: $clr-26
        text-decoration: none
        outline: 0

        i
          transition: $transition
          opacity: .8

    &.lang
      padding: 6px 10px
      margin: 0
      width: inherit
      display: flex
      align-items: center
      justify-content: center
      border-radius: 6px
      gap: 10px

      i
        border-radius: 100px
        overflow: hidden
        width: 18px
        height: 18px
        align-items: center
        justify-content: center

        &:before
          border-radius: 2px
          overflow: hidden

    &.themes
      padding: 4px
      margin: 0
      width: inherit
      display: flex
      align-items: center
      justify-content: center
      border-radius: 8px

    &.hidden
      display: none

[data-theme="light"]
  .button, button
    align-items: center
    appearance: none
    background: $clr-black
    border-radius: $border-radius
    border-width: 0
    color: $clr-white
    cursor: pointer
    display: flex
    font-size: 14px
    font-weight: 600
    justify-content: center
    line-height: 24px
    padding: 12px 32px
    text-align: center
    text-decoration: none
    white-space: nowrap
    transition: $transition

    &:hover
      background: $clr-2e
      color: $clr-white
      text-decoration: none
      outline: 0

    &:active
      background: $clr-4e
      color: $clr-white
      text-decoration: none
      outline: 0
      opacity: 0.8

    &:disabled
      opacity: .5
      pointer-events: none
      cursor: default

    &.medium
      padding: 10px 24px
      font-size: 14px
      font-weight: 600

    &.small
      padding: 8px 16px
      font-size: 14px
      line-height: 20px

    &.black
      background: $clr-white
      color: $clr-black

      &:hover
        background: $clr-f5
        color: $clr-black
        text-decoration: none
        outline: 0

      &:active
        background: $clr-f5
        color: $clr-black
        text-decoration: none
        outline: 0

    &.soc
      background: $clr-f5
      padding: 12px
      color: $clr-black

      i
        transition: $transition
        opacity: .5

      &:hover
        background: $clr-f5
        text-decoration: none
        outline: 0

        i
          transition: $transition
          opacity: 1

      &:active
        background: $clr-f5
        text-decoration: none
        outline: 0

        i
          transition: $transition
          opacity: .8

    &.lang
      padding: 6px 10px
      margin: 0
      width: inherit
      display: flex
      align-items: center
      justify-content: center
      border-radius: 6px
      gap: 10px

      i
        border-radius: 2px
        overflow: hidden

    &.themes
      padding: 4px
      margin: 0
      width: inherit
      display: flex
      align-items: center
      justify-content: center
      border-radius: 8px

    &.hidden
      display: none