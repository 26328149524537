[data-theme="dark"]
  input
    //border: 1px solid $clr-4e
    border: none
    border-radius: $border-radius
    padding: 12px 24px
    font-weight: 400
    font-size: 14px
    color: $clr-8e
    opacity: 1
    line-height: 24px
    background: $clr-26
    transition: $transition
    margin: 0

    &:-webkit-autofill
      &::first-line
        font-family: "RF Dewi" !important
        font-weight: 400
        font-size: 15px

    &::-webkit-input-placeholder
      font-weight: 400
      font-size: 14px
      color: $clr-8e
      line-height: 24px
      background: 0
      transition: $transition

    &::-moz-placeholder
      font-weight: 400
      font-size: 14px
      color: $clr-8e
      line-height: 24px
      background: 0
      transition: $transition

    &:-ms-input-placeholder
      font-weight: 400
      font-size: 14px
      color: $clr-8e
      line-height: 24px
      background: 0
      transition: $transition

    &::-ms-input-placeholder
      font-weight: 400
      font-size: 14px
      color: $clr-8e
      line-height: 24px
      background: 0
      transition: $transition

    &::placeholder
      font-weight: 400
      font-size: 14px
      color: $clr-8e
      line-height: 24px
      background: 0
      transition: $transition

    &:focus
      //border: 1px solid $clr-8e
      border: none
      color: $clr-white
      outline: 0 !important
      opacity: 1

      &::-webkit-input-placeholder
        color: $clr-4e

      &::-moz-placeholder
        color: $clr-4e

      &:-ms-input-placeholder
        color: $clr-4e

      &::-ms-input-placeholder
        color: $clr-4e

      &::placeholder
        color: $clr-4e

    &:hover
      opacity: 1
      background: $clr-26

    &:disabled
      opacity: .5 !important

  .input
    &::-webkit-search-decoration
      -webkit-appearance: none

[data-theme="light"]
  input
    //border: 1px solid $clr-df
    border: none
    border-radius: $border-radius
    padding: 12px 24px
    font-weight: 400
    font-size: 14px
    color: $clr-black
    opacity: 1
    line-height: 24px
    background: $clr-f5
    transition: $transition
    margin: 0

    &:-webkit-autofill
      &::first-line
        font-family: "RF Dewi" !important
        font-weight: 400
        font-size: 15px

    &::-webkit-input-placeholder
      font-weight: 400
      font-size: 14px
      color: $clr-8e
      line-height: 24px
      background: 0
      transition: $transition

    &::-moz-placeholder
      font-weight: 400
      font-size: 14px
      color: $clr-8e
      line-height: 24px
      background: 0
      transition: $transition

    &:-ms-input-placeholder
      font-weight: 400
      font-size: 14px
      color: $clr-8e
      line-height: 24px
      background: 0
      transition: $transition

    &::-ms-input-placeholder
      font-weight: 400
      font-size: 14px
      color: $clr-8e
      line-height: 24px
      background: 0
      transition: $transition

    &::placeholder
      font-weight: 400
      font-size: 14px
      color: $clr-8e
      line-height: 24px
      background: 0
      transition: $transition

    &:focus
      //border: 1px solid $clr-8e
      border: none
      color: $clr-black
      outline: 0 !important
      opacity: 1

      &::-webkit-input-placeholder
        color: $clr-4e

      &::-moz-placeholder
        color: $clr-4e

      &:-ms-input-placeholder
        color: $clr-4e

      &::-ms-input-placeholder
        color: $clr-4e

      &::placeholder
        color: $clr-4e

    &:hover
      opacity: 1
      background: $clr-f5

    &:disabled
      opacity: 0.5 !important

  .input
    &::-webkit-search-decoration
      -webkit-appearance: none