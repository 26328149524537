[data-theme="dark"]
  .select
    .select__control
      background-color: $clr-26
      border: none
      box-shadow: none
      border-radius: $border-radius
      padding: 0
      font-weight: 300
      font-size: 14px
      line-height: 24px
      opacity: 1
      display: flex
      width: 100%
      height: 48px
      position: relative
      z-index: 1
      box-sizing: border-box
      transition: $transition
      cursor: pointer

      .select__value-container
        padding: 12px 32px 12px 24px

        .select__single-value
          font-size: 14px
          overflow: hidden
          font-weight: 400
          color: $clr-8e
          opacity: 1
          margin: 0
          transition: $transition

        input
          padding: 0

      .select__indicators
        margin-right: 8px

        .select__indicator-separator
          display: none

        .select__indicator
          width: 24px
          height: 24px
          padding: 4px
          display: flex
          align-items: center
          justify-content: center
          color: $clr-white
          opacity: 0.5
          transition: $transition

    .select__control--is-focused
      .select__value-container
        .select__single-value
          color: $clr-white

      .select__indicators
        .select__indicator
          opacity: 1
          transform: rotate(180deg)

    .select__menu
      background: $clr-26
      border: none
      border-radius: $border-radius
      border-top: 0
      box-shadow: 0px 4px 12px 0px rgb(0 0 0/20%)
      box-sizing: border-box
      overflow: hidden
      left: 0
      margin: 0
      position: absolute
      top: 100%
      width: 100%
      z-index: 10
      padding: 10px
      transition: $transition
      margin-top: 10px

      .select__menu-list
        padding: 0
        --scrollbar-foreground: #2E2E2E
        --scrollbar-background: $clr-26
        --scrollbar-size: 16px
        scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background)

        &::-webkit-scrollbar
          width: var(--scrollbar-size)
          height: var(--scrollbar-size)

        &::-webkit-scrollbar-thumb
          border-radius: $border-radius
          border: 6px solid $clr-26
          background: var(--scrollbar-foreground)

        &::-webkit-scrollbar-track
          border-radius: 0px
          background: var(--scrollbar-background)

        .select__option
          opacity: 1
          cursor: pointer
          background: $clr-26
          border-radius: $border-radius-2x
          color: $clr-white
          display: block
          font-weight: 300
          font-size: 0.875rem
          line-height: 20px
          height: auto
          padding: 12px
          text-decoration: none
          transition: $transition

          &.select__option--is-selected
            background: $clr-22 !important

          &:hover
            background: $clr-22 !important

[data-theme="light"]
  .select
    .select__control
      background-color: $clr-f5
      border: none
      box-shadow: none
      border-radius: $border-radius
      padding: 0
      font-weight: 300
      font-size: 14px
      line-height: 24px
      opacity: 1
      display: flex
      width: 100%
      height: 48px
      position: relative
      z-index: 1
      box-sizing: border-box
      transition: $transition
      cursor: pointer

      .select__value-container
        padding: 12px 32px 12px 24px

        .select__single-value
          font-size: 14px
          overflow: hidden
          font-weight: 400
          color: $clr-black
          opacity: 1
          margin: 0
          transition: $transition

        input
          padding: 0

      .select__indicators
        margin-right: 8px

        .select__indicator-separator
          display: none

        .select__indicator
          width: 24px
          height: 24px
          padding: 4px
          display: flex
          align-items: center
          justify-content: center
          color: $clr-black
          opacity: 0.5
          transition: $transition

    .select__control--is-focused
      .select__value-container
        .select__single-value
          color: $clr-black

      .select__indicators
        .select__indicator
          opacity: 1
          transform: rotate(180deg)

    .select__menu
      background: $clr-white
      border: none
      border-radius: $border-radius
      border-top: 0
      box-shadow: 0px 4px 12px 0px rgb(0 0 0/20%)
      box-sizing: border-box
      overflow: hidden
      left: 0
      margin: 0
      position: absolute
      top: 100%
      width: 100%
      z-index: 10
      padding: 10px
      transition: $transition
      margin-top: 10px

      .select__menu-list
        padding: 0
        --scrollbar-foreground: #E2E2E2
        --scrollbar-background: $clr-white
        --scrollbar-size: 16px
        scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background)

        &::-webkit-scrollbar
          width: var(--scrollbar-size)
          height: var(--scrollbar-size)

        &::-webkit-scrollbar-thumb
          border-radius: 10px
          border: 6px solid $clr-white
          background: var(--scrollbar-foreground)

        &::-webkit-scrollbar-track
          border-radius: 0px
          background: var(--scrollbar-background)

        .select__option
          opacity: 1
          cursor: pointer
          background: $clr-white
          border-radius: $border-radius-2x
          color: $clr-black
          display: block
          font-weight: 300
          font-size: 0.875rem
          line-height: 20px
          height: auto
          padding: 12px
          text-decoration: none
          transition: $transition

          &.select__option--is-selected
            background: $clr-f5 !important

          &:hover
            background: $clr-f5 !important