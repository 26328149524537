[data-theme="dark"]
  footer
    margin-top: 50px
    padding: 20px 0
    border-top: 1px solid $clr-2e
    bottom: 0
    width: 100%
    background: $clr-black

    .container
      display: flex
      align-items: center
      justify-content: space-between

    .left-block
      .copyright
        display: flex
        color: $clr-8e
        p
          color: $clr-8e

    .right-block
      display: flex
      align-items: center
      gap: 24px

      p
        color: $clr-8e

      a
        color: $clr-8e
        text-decoration: none
        transition: $transition

        &:hover
          text-decoration: underline

      .separator
        display: flex
        align-items: center
        justify-content: center
        margin-right: -10px

        &:before
          content: ""
          width: 1px
          height: 20px
          background: $clr-2e
          position: relative
          display: block

[data-theme="light"]
  footer
    margin-top: 50px
    padding: 20px 0
    border-top: 1px solid $clr-e2
    bottom: 0
    width: 100%
    background: $clr-white

    .container
      display: flex
      align-items: center
      justify-content: space-between

    .left-block
      .copyright
        display: flex
        color: $clr-8e
        p
          color: $clr-8e

    .right-block
      display: flex
      align-items: center
      gap: 24px

      p
        color: $clr-8e

      a
        color: $clr-8e
        text-decoration: none
        transition: $transition

        &:hover
          text-decoration: underline

      .separator
        display: flex
        align-items: center
        justify-content: center
        margin-right: -10px

        &:before
          content: ""
          width: 1px
          height: 20px
          background: $clr-e2
          position: relative
          display: block