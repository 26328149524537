.main
  margin-top: 50px
  box-sizing: border-box
  display: block
  min-height: calc(100vh - 256px)
  position: relative
  overflow: auto

main
  box-sizing: border-box
  display: block
  min-height: calc(100vh - 256px)
  position: relative
  padding-left: 12px
  padding-right: 0
  overflow: auto

  .content
    grid-gap: 24px
    display: grid
    grid-template-columns: repeat(10, 1fr)
    grid-template-rows: auto
    position: relative

  .content-orders
    grid-column: span 7
    width: 100%

    h1
      margin-bottom: 24px

  .content-users
    grid-column: span 10
    width: 100%

    h1
      margin-bottom: 24px

  .right-block
    grid-column: span 3
    width: 100%

.not-found
  position: absolute
  top: 0px
  right: 0
  left: 0
  bottom: 0
  margin: auto
  display: flex
  align-items: center
  justify-content: center

  .not-found-block
    text-align: center
    justify-content: center
    align-items: center
    display: flex
    flex-direction: column
    gap: 50px

    p
      color: $clr-8e

    a
      text-align: center
      padding: 12px 32px
